import React, { useState } from 'react';
import './TokenImage.css';

interface TokenImageProps {
  src: string;
  alt: string;
  className?: string;
  style?: React.CSSProperties;
}

const TokenImage: React.FC<TokenImageProps> = ({ src, alt, className, style }) => {
  const [hasError, setHasError] = useState<boolean>(false);

  return hasError || src.endsWith("null") ? (
    <div
      className={`${className} token-placeholder`}
      style={style}
    />
  ) : (
    <img
      src={src}
      alt={alt}
      className={className}
      style={style}
      onError={() => setHasError(true)}
    />
  );
};

export default TokenImage; 