import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import {
    Table,
    Input,
    Select,
    Row,
    Col,
    Typography,
    Card,
    Tooltip,
    Descriptions,
    Button,
    Space,
    Alert,
} from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import {
    ApiLimitOrder,
    TableFilters,
    ApiLimitResponse,
} from './types';
import './LimitOrdersTable.css';
import { SorterResult } from 'antd/es/table/interface';
import { SearchOutlined } from '@ant-design/icons';
import TokenImage from './TokenImage';
import { Link } from 'react-router-dom';

const { Option } = Select;

interface LimitOrdersTableProps {
    title: string;
    filterKey: 'historical';
}

const LimitOrdersTable: React.FC<LimitOrdersTableProps> = ({
    title,
    filterKey,
}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { account } = useParams<{ account: string }>();

    const [filters, setFilters] = useState<TableFilters>({
        search: account || '',
        tokenMint: '',
        minAmountUsd: 0,
    });

    const [pagination, setPagination] = useState<TablePaginationConfig>({
        current: 1,
        pageSize: 15,
        showSizeChanger: true,
        pageSizeOptions: ['5', '10', '15', '20', '50'],
    });

    const [sortField, setSortField] = useState<string | undefined>(
        'amountUsd'
    );

    const [sortOrder, setSortOrder] = useState<'ascend' | 'descend'>(
        'descend'
    );

    const [data, setData] = useState<ApiLimitOrder[]>([]);
    const [totalPages, setTotalPages] = useState<number>(1);
    const [expandedRowKeys, setExpandedRowKeys] = useState<React.Key[]>([]);
    const isMounted = useRef(false);
    const [minAmountUsd, setMinAmountUsd] = useState<number | null>(0);
    const latestCreatedAtRef = useRef<number>(0);
    const [recentlyAddedRows, setRecentlyAddedRows] = useState<Set<string>>(new Set());
    const [loadingPage, setLoadingPage] = useState<boolean>(false);
    const isFirstLoad = useRef<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    // Synchronize filters with account parameter
    useEffect(() => {
        setFilters((prev) => {
            const newSearch = account || '';
            if (prev.search === newSearch) {
                return prev;
            }
            return { ...prev, search: newSearch };
        });
        setPagination((prev) => {
            if (prev.current === 1) {
                return prev;
            }
            return { ...prev, current: 1 };
        });
        setSortField((prev) => (prev !== 'amountUsd' ? 'amountUsd' : prev));
        setSortOrder((prev) => (prev !== 'descend' ? 'descend' : prev));
    }, [account, filterKey]);

    // Fetch limit orders
    useEffect(() => {
        const fetchLimitOrders = async () => {
            setLoadingPage(true);
            setError(null);
            try {
                const apiParams = new URLSearchParams();
                apiParams.append('filter', filterKey);

                if (filters.search) apiParams.append('search', filters.search);
                if (filters.tokenMint) apiParams.append('tokenMint', filters.tokenMint);
                if (minAmountUsd !== null) apiParams.append('minAmountUsd', minAmountUsd.toString());
                apiParams.append('page', (pagination.current ?? 1).toString());
                apiParams.append('size', (pagination.pageSize ?? 15).toString());

                if (sortField && sortOrder) {
                    apiParams.append('sortField', sortField);
                    apiParams.append('sortOrder', sortOrder === 'ascend' ? 'ascend' : 'descend');
                }

                const response = await fetch(`https://api.cosmic.markets/api/limit/all?${apiParams.toString()}`);
                if (!response.ok) {
                    throw new Error(`Error fetching Limit Orders: ${response.statusText}`);
                }
                const result: ApiLimitResponse = await response.json();
                const newData = result.content;

                const fetchedMaxCreatedAt = newData.reduce((max, item) => {
                    return item.createdAt > max ? item.createdAt : max;
                }, latestCreatedAtRef.current);

                const newRows = newData.filter(item => item.createdAt > latestCreatedAtRef.current);

                if (
                    newRows.length > 0 &&
                    sortField === 'createdAt' &&
                    sortOrder === 'descend' &&
                    pagination.current === 1 &&
                    !isFirstLoad.current
                ) {
                    const newPublicKeys = newRows.map(item => item.publicKey);
                    setRecentlyAddedRows(prev => {
                        const updated = new Set(prev);
                        newPublicKeys.forEach(key => updated.add(key));
                        return updated;
                    });

                    setTimeout(() => {
                        setRecentlyAddedRows(prev => {
                            const updated = new Set(prev);
                            newPublicKeys.forEach(key => updated.delete(key));
                            return updated;
                        });
                    }, 1500);
                }

                latestCreatedAtRef.current = fetchedMaxCreatedAt;

                if (isFirstLoad.current) {
                    isFirstLoad.current = false;
                }
                setData(newData);
                setTotalPages(result.totalPages);
            } catch (error) {
                console.error('Fetch Limit Orders Error:', error);
                setError((error as Error).message);
                setData([]);
            } finally {
                setLoadingPage(false);
            }
        };

        // Fetch data on mount and when dependencies change
        fetchLimitOrders();
    }, [
        filterKey,
        filters.search,
        filters.tokenMint,
        minAmountUsd,
        pagination.current,
        pagination.pageSize,
        sortField,
        sortOrder,
    ]);

    // Periodic data fetching
    useEffect(() => {
        const intervalId = setInterval(() => {
            // Avoid fetching on initial load
            if (!isFirstLoad.current) {
                console.log('Fetching limit orders');
            }
        }, 50000); // 50 seconds

        return () => clearInterval(intervalId);
    }, [filterKey, filters, pagination, sortField, sortOrder, minAmountUsd]);

    const handleTableChange = (
        newPagination: TablePaginationConfig,
        _: Record<string, any>,
        sorter: SorterResult<ApiLimitOrder> | SorterResult<ApiLimitOrder>[],
        __: any
    ) => {
        setPagination(newPagination);

        if (!Array.isArray(sorter)) {
            if (sorter.order === undefined) {
                const newOrder = sortOrder === 'ascend' ? 'descend' : 'ascend';
                setSortOrder(newOrder);
                if (sorter.field) {
                    setSortField(sorter.field as string);
                }
            } else {
                setSortField(sorter.field as string | undefined);
                setSortOrder(sorter.order ?? 'ascend');
            }
        }

        setLoadingPage(true);
    };

    const getTooltipText = (title: string) => {
        return 'Displays all active Limit Orders.';
    };

    const columns: ColumnsType<ApiLimitOrder> = [
        {
            title: 'Time',
            dataIndex: 'createdAt',
            key: 'createdAt',
            sorter: true,
            sortDirections: ['descend', 'ascend'],
            sortOrder: sortField === 'createdAt' ? sortOrder : undefined,
            render: (timestamp: number) => {
                const date = new Date(timestamp * 1000);
                const timeString = date.toLocaleTimeString('en-US', {
                    hour: '2-digit',
                    minute: '2-digit',
                });
                const fullDateTime = date.toLocaleString('en-US', {
                    dateStyle: 'medium',
                    timeStyle: 'medium',
                });

                return (
                    <Tooltip title={fullDateTime}>
                        <span>{timeString}</span>
                    </Tooltip>
                );
            },
            align: 'center',
            width: 100,
        },
        {
            title: 'Input',
            dataIndex: 'inputTokenSymbol',
            key: 'inputTokenSymbol',
            sorter: true,
            sortDirections: ['descend', 'ascend'],
            sortOrder: sortField === 'inputTokenSymbol' ? sortOrder : undefined,
            render: (text: string, record: ApiLimitOrder) => (
                <span className="input-token-cell">
                    <TokenImage
                        src={`https://wsrv.nl/?w=32&h=32&default=1&output=gif&n=-1&url=${encodeURIComponent(record.inputTokenImage)}`}
                        alt={record.inputTokenSymbol}
                        className="token-image"
                    />
                    <Tooltip title={record.inputTokenName || 'Token Name Not Available'}>
                        <span className="ellipsis">
                            {text && text.length > 8 ? `${text.slice(0, 8)}...` : text}
                        </span>
                    </Tooltip>
                </span>
            ),
        },
        {
            title: 'Output',
            dataIndex: 'outputTokenSymbol',
            key: 'outputTokenSymbol',
            sorter: true,
            sortDirections: ['descend', 'ascend'],
            sortOrder: sortField === 'outputTokenSymbol' ? sortOrder : undefined,
            render: (text: string, record: ApiLimitOrder) => (
                <span className="output-token-cell">
                    <TokenImage
                        src={`https://wsrv.nl/?w=32&h=32&default=1&output=gif&n=-1&url=${encodeURIComponent(record.outputTokenImage)}`}
                        alt={record.outputTokenSymbol}
                        className="token-image"
                    />
                    <Tooltip title={record.outputTokenName || 'Token Name Not Available'}>
                        <span className="ellipsis">
                            {text && text.length > 8 ? `${text.slice(0, 8)}...` : text}
                        </span>
                    </Tooltip>
                </span>
            ),
        },
        {
            title: 'Amount USD',
            dataIndex: 'amountUsd',
            key: 'amountUsd',
            sorter: true,
            sortDirections: ['descend', 'ascend'],
            sortOrder: sortField === 'amountUsd' ? sortOrder : undefined,
            render: (value: number) =>
                `$${value.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                })}`,
            align: 'right',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            sorter: true,
            sortDirections: ['descend', 'ascend'],
            sortOrder: sortField === 'amount' ? sortOrder : undefined,
            render: (value: number) =>
                `${value.toLocaleString('en-US', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                })}`,
            align: 'right',
        },
        {
            title: 'Trader',
            dataIndex: 'maker',
            key: 'maker',
            sorter: true,
            sortDirections: ['descend', 'ascend'],
            sortOrder: sortField === 'maker' ? sortOrder : undefined,
            render: (text: string) => (
                <Link to={`/jupiter/limit/${text}`}>
                    {text}
                </Link>
            ),
        },
        {
            title: 'Order',
            dataIndex: 'publicKey',
            key: 'publicKey',
            sorter: true,
            sortDirections: ['descend', 'ascend'],
            sortOrder: sortField === 'publicKey' ? sortOrder : undefined,
            render: (text: string) => (
                <Link to={`/jupiter/limit/${text}`}>
                    {text}
                </Link>
            ),
        },
    ];

    const rowClassName = (record: ApiLimitOrder) => {
        return recentlyAddedRows.has(record.publicKey) ? 'flash-new-row' : '';
    };

    const expandedRowRender = (record: ApiLimitOrder) => (
        <Descriptions
            bordered
            size="small"
            column={1}
            className="expanded-row-content"
        >
            <Descriptions.Item label={
                <span style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                        src="/jupiter.png"
                        alt="Jupiter Logo"
                        style={{ width: '16px', height: '16px', marginRight: '8px' }}
                    />
                    Trade on Jupiter
                </span>
            }>
                <Space>
                    <Button
                        type="primary"
                        href={`https://jup.ag/swap/SOL-${record.outputMint}?inAmount=1&referrer=F2oAz7ZdaNR46G3eJpgGbHHk3bwEYBJomJczmaVjuJYh&feeBps=10`}
                        target="_blank"
                        style={{ display: 'flex', alignItems: 'center' }}
                    >
                        <TokenImage
                            src={`https://wsrv.nl/?w=32&h=32&default=1&output=gif&n=-1&url=${encodeURIComponent(record.outputTokenImage)}`}
                            alt={`${record.outputTokenSymbol} Logo`}
                            className="token-image"
                        />
                        {`Buy ${record.outputTokenSymbol}`}
                    </Button>
                    <Button
                        type="primary"
                        danger
                        href={`https://jup.ag/swap/${record.inputMint}-SOL?referrer=F2oAz7ZdaNR46G3eJpgGbHHk3bwEYBJomJczmaVjuJYh&feeBps=10`}
                        target="_blank"
                        style={{ display: 'flex', alignItems: 'center' }}
                    >
                        <TokenImage
                            src={`https://wsrv.nl/?w=32&h=32&default=1&output=gif&n=-1&url=${encodeURIComponent(record.inputTokenImage)}`}
                            alt={`${record.inputTokenSymbol} Logo`}
                            className="token-image"
                        />
                        {`Sell ${record.inputTokenSymbol}`}
                    </Button>
                </Space>
            </Descriptions.Item>
            <Descriptions.Item label="Trader">
                <Link to={`/jupiter/limit/${record.maker}`}>
                    {record.maker}
                </Link>
            </Descriptions.Item>
            <Descriptions.Item label="Input Token Mint" span={2}>
                <Link to={`/jupiter/limit/${record.inputMint}`}>
                    {record.inputMint}
                </Link>
                <a
                    href={`https://solscan.io/token/${record.inputMint}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ marginLeft: '8px' }}
                >
                    <img
                        src="/solscan.png"
                        alt="Solscan"
                        style={{ width: '16px', height: '16px' }}
                    />
                </a>
            </Descriptions.Item>
            <Descriptions.Item label="Output Token Mint" span={2}>
                <Link to={`/jupiter/limit/${record.outputMint}`}>
                    {record.outputMint}
                </Link>
                <a
                    href={`https://solscan.io/token/${record.outputMint}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ marginLeft: '8px' }}
                >
                    <img
                        src="/solscan.png"
                        alt="Solscan"
                        style={{ width: '16px', height: '16px' }}
                    />
                </a>
            </Descriptions.Item>
            {/* Add more Description.Items for other fields as needed */}
        </Descriptions>
    );

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setFilters((prev) => ({
            ...prev,
            search: value,
        }));
        setPagination((prev) => ({
            ...prev,
            current: 1, // Reset to first page on filter change
        }));
        
        if (value === '') {
            navigate(`/jupiter/limit`);
        }
    };

    const handleTokenMintChange = (value: string) => {
        setFilters((prev) => ({
            ...prev,
            tokenMint: value,
        }));
        setPagination((prev) => ({
            ...prev,
            current: 1, // Reset to first page on filter change
        }));
    };

    return (
        <Card
            className="limit-orders-table-container"
            title={
                <Row gutter={[16, 16]} align="middle">
                    <Col flex="auto">
                        <div className="orders-title">
                            <img
                                src="/jupiter.png"
                                alt="Jupiter Logo"
                                className="header-logo"
                                draggable="false"
                            />
                            <Tooltip title={getTooltipText(title)}>
                                <Typography.Title level={4} style={{ margin: 0 }}>
                                    {title}
                                </Typography.Title>
                            </Tooltip>
                        </div>
                    </Col>
                    {(
                        <>
                            <Col xs={24} sm={12} md={8} lg={6}>
                                <Input
                                    id={`search-${filterKey}-input`}
                                    name={`search-${filterKey}`}
                                    placeholder="Search..."
                                    value={filters.search}
                                    onChange={handleSearchChange}
                                    allowClear
                                    size="small"
                                    prefix={<SearchOutlined />}
                                />
                            </Col>
                        </>
                    )}
                </Row>
            }
        >
            {error && (
                <Alert
                    message="Error"
                    description={error}
                    type="error"
                    showIcon
                    style={{ marginBottom: '16px' }}
                />
            )}
            <Table
                columns={columns}
                dataSource={data}
                pagination={{
                    current: pagination.current,
                    pageSize: pagination.pageSize ?? 10,
                    total: totalPages * (pagination.pageSize ?? 10),
                    showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '20', '50'],
                }}
                onChange={handleTableChange}
                rowKey="publicKey"
                scroll={{ x: 'max-content' }}
                size="small"
                className="limit-orders-table"
                style={{ marginBottom: '0' }}
                expandable={{
                    expandedRowRender,
                    rowExpandable: () => true,
                    expandRowByClick: true,
                    expandedRowKeys: expandedRowKeys,
                    onExpandedRowsChange: (expandedKeys) => {
                        setExpandedRowKeys([...expandedKeys]);
                    },
                }}
                rowClassName={rowClassName}
                loading={loadingPage}
            />
        </Card>
    );
};

export default LimitOrdersTable; 