import React from 'react';
import { Typography, Card, Space, Button, Row, Col, List, Tag } from 'antd';
import { DollarCircleOutlined, BarChartOutlined, CheckCircleOutlined, CloseCircleOutlined, ArrowRightOutlined } from '@ant-design/icons';
import './AboutContent.css';

const AboutContent: React.FC = () => {
  return (
    <Card className="about-card" bordered={false}>
      <Space direction="vertical" size="large" style={{ width: '100%' }}>
        {/* What is DCA Section */}
        <Card className="inner-card" size="small">
          <Space align="start">
            <DollarCircleOutlined className="section-icon" />
            <div>
              <Typography.Title level={3}>What is Jupiter DCA?</Typography.Title>
              <Typography.Paragraph>
                DCA is a systematic investment strategy that mitigates price volatility risk through 
                periodic, fixed-value acquisitions of assets. Rather than attempting to time market 
                entry points, this approach implements a consistent investment schedule - for instance, 
                allocating $100 to Bitcoin on a weekly basis, irrespective of market conditions. This 
                methodology effectively reduces timing risk while building positions through systematic 
                accumulation. Jupiter DCA enables this strategy by providing free DCA infrastructure 
                on Solana's network.
              </Typography.Paragraph>
              <Typography.Paragraph>
                Our platform democratizes access to DCA order data, giving you visibility into significant upcoming market 
                movements. By monitoring when and where large DCA orders are placed, traders can make more informed decisions 
                about their own market entry points. This transparency allows everyone to see the same market signals that 
                were previously only available to a select few, creating a more level playing field for all participants.
              </Typography.Paragraph>
              <Tag color="blue">Automated Execution</Tag>
              <Tag color="green">Capital Efficient</Tag>
              <Tag color="purple">Order Transparency</Tag>
            </div>
          </Space>
        </Card>

        {/* Pros and Cons Section */}
        <Card className="inner-card">
          <Space align="start">
            <BarChartOutlined className="section-icon" />
            <div style={{ width: '100%' }}>
              <Typography.Title level={3}>Pros and Cons of Jupiter DCA</Typography.Title>
              <Row gutter={24}>
                <Col xs={24} md={12}>
                  <List
                    header={
                      <Space>
                        <CheckCircleOutlined style={{ color: '#52c41a' }} />
                        <Typography.Text strong>Advantages</Typography.Text>
                      </Space>
                    }
                    bordered
                    dataSource={[
                      'Reduced slippage and market impact.',
                      'Execute larger orders that lack standing liquidity.',
                      'Eliminates behavioral bias in execution strategy.',
                    ]}
                    renderItem={(item) => (
                      <List.Item>
                        <Typography.Text>{item}</Typography.Text>
                      </List.Item>
                    )}
                  />
                </Col>
                <Col xs={24} md={12}>
                  <List
                    header={
                      <Space>
                        <CloseCircleOutlined style={{ color: '#ff4d4f' }} />
                        <Typography.Text strong>Limitations</Typography.Text>
                      </Space>
                    }
                    bordered
                    dataSource={[
                      'Asset being purchased may rise in price during execution.',
                      'Maintains full beta exposure to bidding asset.',
                    ]}
                    renderItem={(item) => (
                      <List.Item>
                        <Typography.Text>{item}</Typography.Text>
                      </List.Item>
                    )}
                  />
                </Col>
              </Row>
            </div>
          </Space>
        </Card>

        {/* CTA Section */}
        <Row justify="center">
          <Space direction="vertical" align="center">
            <Typography.Text>Ready to start your DCA journey?</Typography.Text>
            <Button 
              type="primary" 
              icon={<ArrowRightOutlined />}
              href="https://jup.ag/dca/?referrer=F2oAz7ZdaNR46G3eJpgGbHHk3bwEYBJomJczmaVjuJYh&feeBps=10"
              target="_blank"
              size="large"
            >
              Get Started with Jupiter DCA
            </Button>
          </Space>
        </Row>
      </Space>
    </Card>
  );
};

export default AboutContent;