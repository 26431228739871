import React, { useState, useEffect, useRef } from 'react';
import { Table, Typography, Card, Progress, Switch, Tooltip } from 'antd';
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { AggregateData } from './types';
import './AggregateTable.css';
import TokenImage from './TokenImage';
import { Link } from 'react-router-dom';

interface AggregateTableProps {
  aggregates: AggregateData[];
  getAggregateColumns: () => ColumnsType<AggregateData>;
  isLoading: boolean;
  error: string | null;
  hideStables: boolean;
  onToggleHideStables: (value: boolean) => void;
}

const AggregateTable: React.FC<AggregateTableProps> = ({
  aggregates,
  getAggregateColumns,
  isLoading,
  error,
  hideStables,
  onToggleHideStables,
}) => {
  const prevAggregates = useRef<AggregateData[]>([]);
  const [flashStates, setFlashStates] = useState<Record<string, 'increase' | 'decrease' | null>>({});
  const [progress, setProgress] = useState<number>(0);
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 5,
    showSizeChanger: true,
    pageSizeOptions: ['5', '10', '20'],
  });

  const filteredAggregates = React.useMemo(() => {
    if (!hideStables) return aggregates;
    const stableSymbols = ['USDT', 'USDC', 'SOL'];
    return aggregates.filter(agg => !stableSymbols.includes(agg.tokenSymbol));
  }, [aggregates, hideStables]);

  useEffect(() => {
    const newFlashStates: Record<string, 'increase' | 'decrease' | null> = {};

    filteredAggregates.forEach((agg) => {
      const prev = prevAggregates.current.find(p => p.token === agg.token);
      if (prev) {
        let totalChange: 'increase' | 'decrease' | null = null;

        // Check for changes in Total USD
        if (agg.totalUsd > prev.totalUsd) {
          newFlashStates[`${agg.token}-totalUsd`] = 'increase';
          totalChange = 'increase';
        } else if (agg.totalUsd < prev.totalUsd) {
          newFlashStates[`${agg.token}-totalUsd`] = 'decrease';
          totalChange = 'decrease';
        }

        // If Total USD changed, set flash state for Token cell
        if (totalChange) {
          newFlashStates[`${agg.token}-token`] = totalChange;
        }

        // Check for changes in Buys USD
        if (agg.buysUsd > prev.buysUsd) {
          newFlashStates[`${agg.token}-buysUsd`] = 'increase';
        } else if (agg.buysUsd < prev.buysUsd) {
          newFlashStates[`${agg.token}-buysUsd`] = 'decrease';
        }

        // Check for changes in Sells USD
        if (agg.sellsUsd > prev.sellsUsd) {
          newFlashStates[`${agg.token}-sellsUsd`] = 'increase';
        } else if (agg.sellsUsd < prev.sellsUsd) {
          newFlashStates[`${agg.token}-sellsUsd`] = 'decrease';
        }
      }
    });

    // Update flash states
    setFlashStates(newFlashStates);
    // Update previous aggregates
    prevAggregates.current = filteredAggregates;

    // Reset progress
    setProgress(0);

    // Progress bar settings for 3.9 seconds
    const totalDuration = 3900; // 3.9 seconds
    const interval = 50; // 50ms
    const steps = totalDuration / interval; // 78 steps
    const increment = 100 / steps; // ~1.282%

    const progressInterval = setInterval(() => {
      setProgress(prev => {
        const nextProgress = prev + increment;
        return nextProgress > 100 ? 100 : nextProgress;
      });
    }, interval);

    // Clear flash states after animation duration
    const flashTimer = setTimeout(() => {
      setFlashStates({});
    }, 1500); // Duration matches the CSS animation duration

    return () => {
      clearInterval(progressInterval);
      clearTimeout(flashTimer);
    };
  }, [filteredAggregates]);

  const handleTableChange = (newPagination: TablePaginationConfig) => {
    setPagination(newPagination);
  };

  const getColumnWithFlash = (columns: ColumnsType<AggregateData>): ColumnsType<AggregateData> => {
    return columns.map((col) => {
      const newCol = {
        ...col,
        width: col.width || 100,
        ellipsis: true,
        onCell: (record: AggregateData) => ({
          ...((col.onCell && typeof col.onCell === 'function') 
            ? col.onCell(record) 
            : {}),
          style: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
          className: flashStates[`${record.token}-${col.key}`] 
            ? `flash-${flashStates[`${record.token}-${col.key}`]}` 
            : '',
        }),
        render: (value: any, record: AggregateData, index: number) => {
          if (col.key === 'token') {
            return col.render ? col.render(value, record, index) : value;
          } else {
            return col.render ? col.render(value, record, index) : value;
          }
        },
      };

      return newCol;
    });
  };

  const combinedColumn: ColumnsType<AggregateData> = [
    {
      title: 'Token',
      dataIndex: 'combined',
      key: 'combined',
      render: (text: string, record: AggregateData) => (
        <Tooltip title={record.token}>
          <Link 
            to={`/jupiter/dca/${record.tokenMint}`} 
            className="token-link"
            style={{ display: 'flex', alignItems: 'center'}}
          >
            <TokenImage
              className="token-image"
              src={record.tokenImage ? `https://wsrv.nl/?w=32&h=32&default=1&output=gif&n=-1&url=${encodeURIComponent(record.tokenImage)}` : ''}
              alt={record.tokenSymbol}
              style={{ marginRight: '8px', width: '24px', height: '24px' }}
            />
            {`${record.tokenSymbol}`}
          </Link>
        </Tooltip>
      ),
      sorter: (a: AggregateData, b: AggregateData) => a.tokenSymbol.localeCompare(b.tokenSymbol),
      width: 10,
    },
  ];

  const titleContent = (
    <div className="aggregate-table-header">
      <div className="aggregate-title">
        <img
          src="/jupiter.png"
          alt="Jupiter Logo"
          className="header-logo"
          draggable="false"
        />
        <Typography.Title level={4} style={{ margin: 0 }} className="aggregate-header">
          Jupiter DCA Trade Volume by Token
        </Typography.Title>
      </div>
      <div className="aggregate-header-controls">
        <Typography.Text className="hide-stables-text">Hide Stables</Typography.Text>
        <Switch
          checked={hideStables}
          onChange={onToggleHideStables}
          style={{ marginLeft: 8 }}
        />
        <Progress
          type="circle"
          percent={progress}
          size={20}
          strokeColor={{
            '0%': '#108ee9',
            '100%': '#87d068',
          }}
          trailColor="#d9d9d9"
          format={() => 'Time until next refresh'}
          aria-label="Time until next refresh"
          style={{ marginLeft: 16 }}
        />
      </div>
    </div>
  );

  return (
    <Card
      className="table-card"
      title={titleContent}
    >
      {error ? (
        <Typography.Text type="danger">{error}</Typography.Text>
      ) : (
        <Table
          columns={[combinedColumn[0], ...getColumnWithFlash(getAggregateColumns())]}
          dataSource={filteredAggregates}
          pagination={pagination}
          onChange={handleTableChange}
          rowKey="tokenMint"
          size="small"
          bordered
          style={{ backgroundColor: '#1e1e1e' }}
          loading={isLoading}
          scroll={{ x: 'max-content' }}
        />
      )}
    </Card>
  );
};

export default AggregateTable;
