import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  Table,
  Input,
  Select,
  Row,
  Col,
  Typography,
  Card,
  Tooltip,
  Progress,
  Descriptions,
  Button,
  Space,
} from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import {
  ApiDcaOrder,
  TableFilters,
  ApiDcaResponse,
} from './types';
import './OrdersTable.css';
import { SorterResult } from 'antd/es/table/interface';
import { SearchOutlined, RightOutlined } from '@ant-design/icons';
import TokenImage from './TokenImage';
import { Link } from 'react-router-dom';

const { Option } = Select;

interface OrdersTableProps {
  title: string;
  filterKey: 'next-day' | 'all';
}

const OrdersTable: React.FC<OrdersTableProps> = ({
  title,
  filterKey,
}) => {
  const [filters, setFilters] = useState<TableFilters>({
    search: '',
    tokenMint: '',
    minAmountUsd: 0,
  });

  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 10,
    showSizeChanger: true,
    pageSizeOptions: ['5', '10', '20', '50'],
  });

  // Initialize sorting state based on filterKey
  const [sortField, setSortField] = useState<string | undefined>(
    filterKey === 'next-day' ? 'createdAt' : 'amountUsd'
  );
  const [sortOrder, setSortOrder] = useState<
    'ascend' | 'descend'
  >(filterKey === 'next-day' ? 'descend' : 'descend'); // Assuming 'descend' for both

  const [data, setData] = useState<ApiDcaOrder[]>([]);
  const [totalPages, setTotalPages] = useState<number>(1);

  // State to manage expanded rows
  const [expandedRowKeys, setExpandedRowKeys] = useState<React.Key[]>([]);

  // Ref to track component mount status
  const isMounted = useRef(false);

  const [minAmountUsd, setMinAmountUsd] = useState<number | null>(null);

  // Ref to store the latest createdAt timestamp
  const latestCreatedAtRef = useRef<number>(0);

  // State to track recently added rows for flashing
  const [recentlyAddedRows, setRecentlyAddedRows] = useState<Set<string>>(new Set());

  // State to manage loading during page changes
  const [loadingPage, setLoadingPage] = useState<boolean>(false);

  // Ref to track if it's the first load
  const isFirstLoad = useRef<boolean>(true);

  const fetchDcaOrders = useCallback(async () => {
    setLoadingPage(true);
    try {
      const params = new URLSearchParams();
      params.append('filter', filterKey);
      if (filters.search) params.append('search', filters.search);
      if (filters.tokenMint) params.append('tokenMint', filters.tokenMint);
      if (minAmountUsd !== null) params.append('minAmountUsd', minAmountUsd.toString());
      params.append('page', (pagination.current ?? 1).toString());
      params.append('size', pagination.pageSize?.toString() || '10');

      if (sortField && sortOrder) {
        params.append('sortField', sortField);
        params.append('sortOrder', sortOrder === 'ascend' ? 'ascend' : 'descend');
      }

      const response = await fetch(`https://api.cosmic.markets/api/dca/all?${params.toString()}`);
      if (!response.ok) {
        throw new Error(`Error fetching DCA orders: ${response.statusText}`);
      }
      const result: ApiDcaResponse = await response.json();
      const newData = result.content;

      // Determine the maximum createdAt timestamp from the fetched data
      const fetchedMaxCreatedAt = newData.reduce((max, item) => {
        return item.createdAt > max ? item.createdAt : max;
      }, latestCreatedAtRef.current);

      // Identify new rows with createdAt greater than the previously tracked timestamp
      const newRows = newData.filter(item => item.createdAt > latestCreatedAtRef.current);

      if (
        newRows.length > 0 &&
        sortField === 'createdAt' &&
        sortOrder === 'descend' &&
        pagination.current === 1 &&
        !isFirstLoad.current
      ) {
        const newPublicKeys = newRows.map(item => item.publicKey);
        setRecentlyAddedRows(prev => {
          const updated = new Set(prev);
          newPublicKeys.forEach(key => updated.add(key));
          return updated;
        });

        // Remove the flash after animation duration (e.g., 1500ms)
        setTimeout(() => {
          setRecentlyAddedRows(prev => {
            const updated = new Set(prev);
            newPublicKeys.forEach(key => updated.delete(key));
            return updated;
          });
        }, 1500); // Duration matches the CSS animation duration
      }

      // Update the latestCreatedAtRef with the new maximum
      latestCreatedAtRef.current = fetchedMaxCreatedAt;
      
      // After the first load, set isFirstLoad to false
      if (isFirstLoad.current) {
        isFirstLoad.current = false;
      }
      setData(newData);
      setTotalPages(result.totalPages);
    } catch (error) {
      console.error('Fetch DCA Orders Error:', error);
    } finally {
      setLoadingPage(false);
    }
  }, [filterKey, filters, pagination, sortField, sortOrder, minAmountUsd]);

  useEffect(() => {
    if (isMounted.current) {
      fetchDcaOrders();
    } else {
      isMounted.current = true;
      fetchDcaOrders();
    }
  }, [fetchDcaOrders]);

  /**
   * Polling Effect: Refresh data every 4 seconds
   */
  useEffect(() => {
    const intervalId = setInterval(() => {
      fetchDcaOrders();
    }, 6000); // 6000ms = 6 seconds

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [fetchDcaOrders]);

  const handleTableChange = (
    newPagination: TablePaginationConfig,
    _: Record<string, any>,
    sorter: SorterResult<ApiDcaOrder> | SorterResult<ApiDcaOrder>[],
    __: any
  ) => {
    setPagination(newPagination);

    if (!Array.isArray(sorter)) {
      if (sorter.order === undefined) {
        // Toggle sortOrder between 'ascend' and 'descend'
        const newOrder = sortOrder === 'ascend' ? 'descend' : 'ascend';
        setSortOrder(newOrder);
        if (sorter.field) {
          setSortField(sorter.field as string);
        }
      } else {
        setSortField(sorter.field as string | undefined);
        setSortOrder(sorter.order ?? 'ascend'); // Default to 'ascend' if undefined
      }
    }

    // Show loading animation when switching pages
    setLoadingPage(true);
  };

  const getTooltipText = (title: string) => {
    if (title === 'Jupiter DCA - Newest') {
      return 'Shows DCA orders scheduled to execute within the next 24 hours. These orders represent immediate market impact and can be used to anticipate short-term price movements and liquidity demands.';
    }
    return 'Displays all active DCA orders regardless of execution time. This view helps understand longer-term market trends and overall capital deployment patterns across different tokens.';
  };

  const columns: ColumnsType<ApiDcaOrder> = [
    {
      title: 'Input',
      dataIndex: 'inputTokenSymbol',
      key: 'inputTokenSymbol',
      sorter: true,
      sortDirections: ['descend', 'ascend'], // Restrict sort directions
      sortOrder:
        sortField === 'inputTokenSymbol' ? sortOrder : undefined,
      render: (text: string, record: ApiDcaOrder) => (
        <span className="input-token-cell">
          <TokenImage
            src={`https://wsrv.nl/?w=32&h=32&default=1&output=gif&n=-1&url=${encodeURIComponent(record.inputTokenImage)}` || ''} 
            alt={record.inputTokenSymbol}
            className="token-image"
          />
          <Tooltip title={record.inputTokenName || 'Token Name Not Available'}>
            <span className="ellipsis">
              {text.length > 8 ? `${text.slice(0, 8)}...` : text}
            </span>
          </Tooltip>
        </span>
      ),
    },
    {
      title: 'Output',
      dataIndex: 'outputTokenSymbol',
      key: 'outputTokenSymbol',
      sorter: true,
      sortDirections: ['descend', 'ascend'], // Restrict sort directions
      sortOrder:
        sortField === 'outputTokenSymbol' ? sortOrder : undefined,
      render: (text: string, record: ApiDcaOrder) => (
        <span className="output-token-cell">
          <TokenImage
            src={`https://wsrv.nl/?w=32&h=32&default=1&output=gif&n=-1&url=${encodeURIComponent(record.outputTokenImage)}` || ''}
            alt={record.outputTokenSymbol}
            className="token-image"
          />
          <Tooltip title={record.outputTokenName || 'Token Name Not Available'}>
            <span className="ellipsis">
              {text.length > 8 ? `${text.slice(0, 8)}...` : text}
            </span>
          </Tooltip>
        </span>
      ),
    },
    {
      title: 'Amount',
      dataIndex: 'amountUsd',
      key: 'amountUsd',
      sorter: true,
      sortDirections: ['descend', 'ascend'], // Restrict sort directions
      sortOrder: sortField === 'amountUsd' ? sortOrder : undefined,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Min Amount (USD)"
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => {
              confirm();
              setMinAmountUsd(selectedKeys[0] ? Number(selectedKeys[0]) : null);
            }}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => {
                confirm();
                setMinAmountUsd(selectedKeys[0] ? Number(selectedKeys[0]) : null);
              }}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Filter
            </Button>
            <Button
              onClick={() => {
                setMinAmountUsd(null);
                confirm();
              }}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      render: (value: number) =>
        `$${value.toLocaleString('en-US', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })}`,
      align: 'right',
    },
    {
      title: '$ / Min',
      dataIndex: 'usdSpentPerMinute',
      key: 'usdSpentPerMinute',
      sorter: true,
      sortDirections: ['descend', 'ascend'], // Restrict sort directions
      sortOrder:
        sortField === 'usdSpentPerMinute' ? sortOrder : undefined,
      render: (value: number) => {
        if (value < 1) {
          return '<$1';
        }
        return `$${Math.round(value).toLocaleString('en-US')}`;
      },
      align: 'right',
    },
    {
      title: 'Progress',
      dataIndex: 'progress',
      key: 'progress',
      sorter: true,
      sortDirections: ['descend', 'ascend'], // Restrict sort directions
      sortOrder: sortField === 'progress' ? sortOrder : undefined,
      render: (_text: any, record: ApiDcaOrder) => {
        const completedPercentage = Math.min(
          Number(record.progress.toFixed(0)),
          100
        );

        const getProgressColor = (percent: number) => {
          if (percent > 90) return '#faad14';
          return '#52c41a';
        };

        return (
          <Tooltip title={`${completedPercentage}% Complete`}>
            <div className="progress-row">
              <Progress
                percent={completedPercentage}
                size="small"
                strokeColor={getProgressColor(completedPercentage)}
                showInfo={false}
                className="custom-progress"
              />
              <Typography.Text className="progress-text">
                {completedPercentage}%
              </Typography.Text>
            </div>
          </Tooltip>
        );
      },
      align: 'center',
      width: 100,
    },
    {
      title: 'Time',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: true,
      sortDirections: ['descend', 'ascend'], // Restrict sort directions
      sortOrder: sortField === 'createdAt' ? sortOrder : undefined,
      render: (timestamp: number) => {
        const date = new Date(timestamp * 1000);
        const timeString = date.toLocaleTimeString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
        });
        const fullDateTime = date.toLocaleString('en-US', {
          dateStyle: 'medium',
          timeStyle: 'medium',
        });

        return (
          <Tooltip title={fullDateTime}>
            <span>{timeString}</span>
          </Tooltip>
        );
      },
      align: 'center',
      width: 100,
    },
  ];

  const rowClassName = (record: ApiDcaOrder) => {
    return recentlyAddedRows.has(record.publicKey) ? 'flash-new-row' : '';
  };

  const expandedRowRender = (record: ApiDcaOrder) => (
    <Descriptions
      bordered
      size="small"
      column={1}
      className="expanded-row-content"
    >
      <Descriptions.Item label={
           <span style={{ display: 'flex', alignItems: 'center' }}>
             <img 
               src="/jupiter.png" 
               alt="Jupiter Logo" 
               style={{ width: '16px', height: '16px', marginRight: '8px' }} 
             />
             Trade on Jupiter
           </span>
         }>
        <Space>
          <Button 
            type="primary" 
            href={`https://jup.ag/swap/SOL-${record.outputMint}?inAmount=1&referrer=F2oAz7ZdaNR46G3eJpgGbHHk3bwEYBJomJczmaVjuJYh&feeBps=10`} 
            target="_blank"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <TokenImage 
              src={`https://wsrv.nl/?w=32&h=32&default=1&output=gif&n=-1&url=${encodeURIComponent(record.outputTokenImage)}` || ''} 
              alt={`${record.outputTokenSymbol} Logo`} 
              className="token-image"
            />
            {`Buy ${record.outputTokenSymbol}`}
          </Button>
          <Button 
            type="primary" 
            danger
            href={`https://jup.ag/swap/${record.inputMint}-SOL?referrer=F2oAz7ZdaNR46G3eJpgGbHHk3bwEYBJomJczmaVjuJYh&feeBps=10`} 
            target="_blank"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <TokenImage 
              src={`https://wsrv.nl/?w=32&h=32&default=1&output=gif&n=-1&url=${encodeURIComponent(record.inputTokenImage)}` || ''} 
              alt={`${record.inputTokenSymbol} Logo`} 
              className="token-image"
            />
            {`Sell ${record.inputTokenSymbol}`}
          </Button>
        </Space>
      </Descriptions.Item>
      <Descriptions.Item label="Action" span={2}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
          <span style={{ display: 'flex', alignItems: 'center', gap: '2px' }}>
            <span style={{ marginRight: '1ch' }}>{record.amount}</span>
            <TokenImage
              src={`https://wsrv.nl/?w=32&h=32&default=1&output=gif&n=-1&url=${encodeURIComponent(record.inputTokenImage)}` || ''}
              alt={record.inputTokenSymbol}
              className="token-image"
            />
            <a
              href={`https://solscan.io/token/${record.inputMint}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {record.inputTokenSymbol}
            </a>
          </span>
          <RightOutlined style={{ fontSize: '12px', margin: '0 4px' }} />
          <span style={{ display: 'flex', alignItems: 'center', gap: '2px' }}>
            <TokenImage
              src={`https://wsrv.nl/?w=32&h=32&default=1&output=gif&n=-1&url=${encodeURIComponent(record.outputTokenImage)}` || ''}
              alt={record.outputTokenSymbol}
              className="token-image"
            />
            <a
              href={`https://solscan.io/token/${record.outputMint}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {record.outputTokenSymbol}
            </a>
          </span>
        </div>
      </Descriptions.Item>
      <Descriptions.Item label="Action Details">
        {record.inputTokenName} <RightOutlined style={{ fontSize: '12px', margin: '0 4px' }} /> {record.outputTokenName}
      </Descriptions.Item>
      <Descriptions.Item label="Amount">
        {record.amount} {record.inputTokenSymbol} {"("}$
        {record.amountUsd.toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
        {" USD)"}
      </Descriptions.Item>
      <Descriptions.Item label="USD Spent Per Minute">
        $
        {record.usdSpentPerMinute.toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
      </Descriptions.Item>
      <Descriptions.Item label="Progress">
        {record.progress.toFixed(2)}%
      </Descriptions.Item>
      <Descriptions.Item label="Cycle Frequency">
        {record.cycleFrequency / 60.0} minutes
      </Descriptions.Item>
      <Descriptions.Item label="Created At">
        {new Date(record.createdAt * 1000).toISOString().replace("T"," ").substring(0, 19)}
      </Descriptions.Item>
      <Descriptions.Item label="Next Cycle At">
        {new Date(record.nextCycleAt * 1000).toISOString().replace("T"," ").substring(0, 19)}
      </Descriptions.Item>
      <Descriptions.Item label="Input Token Mint" span={2}>
        <Link to={`/jupiter/dca/${record.inputMint}`}>
          {record.inputMint}
        </Link>
        <a 
          href={`https://solscan.io/token/${record.inputMint}`} 
          target="_blank" 
          rel="noopener noreferrer"
          style={{ marginLeft: '8px' }}
        >
          <img 
            src="/solscan.png" 
            alt="Solscan" 
            style={{ width: '16px', height: '16px' }} 
          />
        </a>
      </Descriptions.Item>
      <Descriptions.Item label="Output Token Mint" span={2}>
        <Link to={`/jupiter/dca/${record.outputMint}`}>
          {record.outputMint}
        </Link>
        <a 
          href={`https://solscan.io/token/${record.outputMint}`} 
          target="_blank" 
          rel="noopener noreferrer"
          style={{ marginLeft: '8px' }}
        >
          <img 
            src="/solscan.png" 
            alt="Solscan" 
            style={{ width: '16px', height: '16px' }} 
          />
        </a>
      </Descriptions.Item>
      <Descriptions.Item label="Trader" span={2}>
        <Link to={`/jupiter/dca/${record.userPublicKey}`}>
          {record.userPublicKey}
        </Link>
        <a 
          href={`https://solscan.io/account/${record.userPublicKey}`} 
          target="_blank" 
          rel="noopener noreferrer"
          style={{ marginLeft: '8px' }}
        >
          <img 
            src="/solscan.png" 
            alt="Solscan" 
            style={{ width: '16px', height: '16px' }} 
          />
        </a>
      </Descriptions.Item>
      <Descriptions.Item label="DCA Account" span={2}>
        <Link to={`/jupiter/dca/${record.publicKey}`}>
          {record.publicKey}
        </Link>
      </Descriptions.Item>
    </Descriptions>
  );

  return (
    <Card
      className="orders-table-container"
      title={
        <Row gutter={[16, 16]} align="middle">
          <Col flex="auto">
            <div className="orders-title">
              <img
                src="/jupiter.png"
                alt="Jupiter Logo"
                className="header-logo"
                draggable="false"
              />
              <Tooltip title={getTooltipText(title)}>
                <Typography.Title level={4} style={{ margin: 0 }}>
                  {title}
                </Typography.Title>
              </Tooltip>
            </div>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <Input
              id={`search-${filterKey}-input`}
              name={`search-${filterKey}`}
              placeholder="Search..."
              onChange={(e) =>
                setFilters((prev) => ({
                  ...prev,
                  search: e.target.value,
                }))
              }
              allowClear
              size="small"
            />
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <Select
              id={`filter-${filterKey}-select`}
              placeholder="Filter by token"
              onChange={(value) =>
                setFilters((prev) => ({
                  ...prev,
                  tokenMint: value,
                }))
              }
              style={{ width: '100%' }}
              allowClear
              size="small"
            >
              <Option value="">All Tokens</Option>
              <Option value="EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v">
                USDC
              </Option>
              <Option value="Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB">
                USDT
              </Option>
              <Option value="6DNSN2BJsaPFdFFc1zP37kkeNe4Usc1Sqkzr9C9vPWcU">
                tBTC
              </Option>
            </Select>
          </Col>
        </Row>
      }
      styles={{
        body: { padding: '16px', paddingBottom: '0px' },
      }}
    >
      <Table
        columns={columns}
        dataSource={data}
        pagination={{
          current: pagination.current,
          pageSize: pagination.pageSize ?? 10,
          total: totalPages * (pagination.pageSize ?? 10),
          showSizeChanger: true,
          pageSizeOptions: ['5', '10', '20', '50'],
        }}
        onChange={handleTableChange}
        rowKey="publicKey"
        scroll={{ x: 'max-content' }}
        size="small"
        className="orders-table"
        style={{ marginBottom: '0' }}
        expandable={{
          expandedRowRender,
          rowExpandable: () => true,
          expandRowByClick: true,
          expandedRowKeys: expandedRowKeys,
          onExpandedRowsChange: (expandedKeys) => {
            setExpandedRowKeys([...expandedKeys]);
          },
          showExpandColumn: false,
        }}
        rowClassName={rowClassName}
        loading={loadingPage} // Show loading only during page changes
      />
    </Card>
  );
};

export default OrdersTable;
