import React, { useState, useEffect, useCallback } from 'react';
import { ConfigProvider, theme, Row, Col } from 'antd';
import './JupiterDCAViewer.css';
import DcaChart from './DcaChart';
import AggregateTable from './AggregateTable';
import OrdersTable from './OrdersTable';
import AboutContent from './AboutContent';
import { AggregateData } from './types';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title as ChartTitle,
  Tooltip as ChartTooltip,
  Legend,
  Colors,
  ChartOptions,
} from 'chart.js';
import { ColumnsType } from 'antd/es/table';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ChartTitle,
  ChartTooltip,
  Legend,
  Colors
);

const truncateTokenSymbol = (symbol: string): string => {
  if (symbol.length <= 8) return symbol;
  return `${symbol.slice(0, 8)}...`;
};

const JupiterDCAViewer: React.FC = () => {
  // States for Aggregates
  const [aggregates, setAggregates] = useState<AggregateData[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  // New state for hiding stables
  const [hideStables, setHideStables] = useState<boolean>(true);

  /**
   * Fetches aggregate data from the API.
   */
  const fetchAggregates = useCallback(async () => {
    try {
      const response = await fetch('https://api.cosmic.markets/api/dca/aggregates');
      if (!response.ok) {
        throw new Error(`Error fetching aggregates: ${response.statusText}`);
      }
      const data = await response.json();
      const mappedData: AggregateData[] = data.map((item: any) => ({
        token: item.token || 'Unknown Token',
        tokenSymbol: item.tokenSymbol || '',
        tokenMint: item.tokenMint || '',
        buysUsd: Number(item.buysUsd) || 0,
        sellsUsd: Number(item.sellsUsd) || 0,
        totalUsd: Number(item.totalUsd) || 0,
        tokenImage: item.tokenImage || '',
      }));

      // Remove duplicates based on tokenMint
      const uniqueAggregates = Array.from(new Map(mappedData.map(item => [item.tokenMint, item])).values());

      setAggregates(uniqueAggregates);
    } catch (error) {
      console.error('Fetch Aggregates Error:', error);
      setError((error as Error).message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  /**
   * Initial fetch for aggregates and set up polling.
   */
  useEffect(() => {
    fetchAggregates();

    // Set up polling for aggregates every 4 seconds
    const intervalId = setInterval(() => {
      fetchAggregates();
    }, 4000); // 4000ms = 4 seconds

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [fetchAggregates]);

  /**
   * Chart Data and Options
   */
  const filteredAggregates = React.useMemo(() => {
    if (!hideStables) return aggregates;
    const stableSymbols = ['USDT', 'USDC', 'SOL'];
    return aggregates.filter(agg => !stableSymbols.includes(agg.tokenSymbol));
  }, [aggregates, hideStables]);

  const chartData = React.useMemo(() => {
    // Sort aggregates by total volume and take top 10
    const top10Aggregates = [...filteredAggregates]
      .sort((a, b) => b.totalUsd - a.totalUsd)
      .slice(0, 10);

    const labels = top10Aggregates.map(agg => truncateTokenSymbol(agg.tokenSymbol));
    const buysData = top10Aggregates.map(agg => agg.buysUsd);
    const sellsData = top10Aggregates.map(agg => agg.sellsUsd);

    return {
      labels,
      datasets: [
        {
          label: 'Buys USD',
          data: buysData,
          backgroundColor: 'rgba(75, 192, 75, 0.6)', // Green
        },
        {
          label: 'Sells USD',
          data: sellsData,
          backgroundColor: 'rgba(255, 99, 132, 0.6)', // Red
        },
      ],
    };
  }, [filteredAggregates]);

  const chartOptions: ChartOptions<'bar'> = React.useMemo(() => ({
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          color: '#e0e0e0',
        },
      },
      tooltip: {
        callbacks: {
          label: (context: any) => {
            const label = context.dataset.label || '';
            const value = context.parsed.y;
            return `${label}: $${value.toLocaleString()}`;
          }
        }
      }
    },
    scales: {
      x: {
        stacked: true,
        ticks: {
          autoSkip: false,
          maxRotation: 90,
          minRotation: 45,
          color: '#e0e0e0',
          font: {
            size: 16, // Match aggregate header font size
          }
        },
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        ticks: {
          color: '#e0e0e0',
          callback: function(value: number | string) { // Modified this line
            return `$${Number(value) / 1000}k`;
          },
          font: {
            size: 14, // Match aggregate header font size
          }
        },
        grid: {
          color: '#333',
        },
      },
    },
  }), []);

  /**
   * Define columns for the Aggregate Table
   */
  const getAggregateColumns = useCallback((): ColumnsType<AggregateData> => [
    {
      title: 'Total ($)',
      dataIndex: 'totalUsd',
      key: 'totalUsd',
      sorter: (a, b) => a.totalUsd - b.totalUsd,
      render: (value: number) => {
        const num = Number(value) || 0;
        return `$${num.toLocaleString("en-US", { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`;
      },
      align: 'right',
      defaultSortOrder: 'descend',
    },
    {
      title: 'Buys ($)',
      dataIndex: 'buysUsd',
      key: 'buysUsd',
      sorter: (a, b) => a.buysUsd - b.buysUsd,
      render: (value: number) => {
        const num = Number(value) || 0;
        return `$${num.toLocaleString("en-US", { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`;
      },
      align: 'right',
    },
    {
      title: 'Sells ($)',
      dataIndex: 'sellsUsd',
      key: 'sellsUsd',
      sorter: (a, b) => a.sellsUsd - b.sellsUsd,
      render: (value: number) => {
        const num = Number(value) || 0;
        return `$${num.toLocaleString("en-US", { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`;
      },
      align: 'right',
    },
  ], []);

  /**
   * Handler for toggling hide stables
   */
  const handleToggleHideStables = (value: boolean) => {
    setHideStables(value);
  };

  return (
    <ConfigProvider
      theme={{
        algorithm: theme.darkAlgorithm,
        token: {
          colorPrimary: '#4caf50',
          colorText: '#e0e0e0',
        },
      }}
    >
      <div className="dca-viewer-container">
        {/* Orders Tables */}
        <Row gutter={[16, 16]}>
          <Col xs={24} lg={12}>
            <OrdersTable
              title="Jupiter DCA - Newest"
              filterKey="next-day"
            />
          </Col>
          <Col xs={24} lg={12}>
            <OrdersTable
              title="Jupiter DCA - Largest"
              filterKey="all"
            />
          </Col>
        </Row>
        
        {/* Charts and Aggregate Table */}
        <Row gutter={[16, 16]}>
          <Col xs={24} lg={12}>
            <DcaChart data={chartData} options={chartOptions} />
          </Col>
          <Col xs={24} lg={12}>
            <AggregateTable
              aggregates={filteredAggregates} // Pass filtered aggregates
              getAggregateColumns={getAggregateColumns}
              isLoading={isLoading}
              error={error}
              hideStables={hideStables} // Pass hideStables state
              onToggleHideStables={handleToggleHideStables} // Pass handler
            />
          </Col>
        </Row>

        {/* About Section */}
        <AboutContent />
      </div>
    </ConfigProvider>
  );
}

export default JupiterDCAViewer;
