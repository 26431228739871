import React from 'react';
import { Card, Typography } from 'antd';
import { Bar } from 'react-chartjs-2';
import { ChartOptions } from 'chart.js';
import './DcaChart.css';

interface DcaChartProps {
  data: any;
  options: ChartOptions<'bar'>;
}

const DcaChart: React.FC<DcaChartProps> = ({ data, options }) => {
  return (
    <Card 
      title={
        <div className="chart-title">
          <img 
            src="/jupiter.png" 
            alt="Jupiter Logo" 
            className="header-logo" 
            draggable="false"
          />
          <Typography.Title level={4} style={{ margin: 0 }} className="chart-header">
            Jupiter DCA Trade Volume by Token
          </Typography.Title>
        </div>
      } 
      bordered 
      className="chart-card"
    >
      <div className="bar-chart-container">
        <Bar data={data} options={options} />
      </div>
    </Card>
  );
};

export default DcaChart;
