/**
 * ApiDocs component providing API reference and documentation.
 * 
 * This component is styled to match the dark theme of the application and is centered
 * within the layout for consistency with other components like About and DCA Viewer.
 * 
 * @component
 */
import React from 'react';
import { Typography, Card, Space, Table, Collapse, List, Alert } from 'antd';
import { BookOutlined, ApiOutlined } from '@ant-design/icons';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { okaidia } from 'react-syntax-highlighter/dist/esm/styles/prism';
import './ApiDocs.css';

const { Title, Paragraph, Text } = Typography;

interface QueryParam {
  name: string;
  type: string;
  required: boolean;
  description: string;
}

interface ResponseField {
  name: string;
  type: string;
  description: string;
}

// Define your query parameters
const queryParameters: QueryParam[] = [
  {
    name: 'filter',
    type: `'next-day' | 'all'`,
    required: true,
    description: 'Filter to specify which DCAs to retrieve.',
  },
  {
    name: 'search',
    type: 'string',
    required: false,
    description: 'Search term to filter DCAs by token symbol, name, or mint address.',
  },
  {
    name: 'tokenMint',
    type: 'string',
    required: false,
    description: 'Filter DCAs by a specific token mint address.',
  },
  {
    name: 'minAmountUsd',
    type: 'number',
    required: false,
    description: 'Minimum USD amount for filtering DCAs.',
  },
  {
    name: 'page',
    type: 'number',
    required: false,
    description: 'Page number for pagination (default is 1).',
  },
  {
    name: 'size',
    type: 'number',
    required: false,
    description: 'Number of items per page (default is 10).',
  },
  {
    name: 'sortField',
    type: 'string',
    required: false,
    description: 'Field name to sort the results by.',
  },
  {
    name: 'sortOrder',
    type: `'ascend' | 'descend'`,
    required: false,
    description: 'Order of sorting (ascending or descending).',
  },
];

// Define your response fields
const responseFields: ResponseField[] = [
  {
    name: 'size',
    type: 'number',
    description: 'Number of items returned in the current page.',
  },
  {
    name: 'totalPages',
    type: 'number',
    description: 'Total number of pages available.',
  },
  {
    name: 'page',
    type: 'number',
    description: 'Current page number.',
  },
  {
    name: 'content',
    type: 'ApiDcaOrder[]',
    description: 'Array of DCA orders.',
  },
];

// Define ApiDcaOrder fields based on src/components/types.ts
const apiDcaOrderFields: ResponseField[] = [
  { name: 'userPublicKey', type: 'string', description: 'Public key of the user.' },
  { name: 'inputMint', type: 'string', description: 'Input token mint address.' },
  { name: 'outputMint', type: 'string', description: 'Output token mint address.' },
  { name: 'amount', type: 'number', description: 'Amount of input tokens per DCA cycle.' },
  { name: 'sellAmount', type: 'number', description: 'Amount of output tokens to sell.' },
  { name: 'scheduledTime', type: 'number', description: 'UNIX timestamp for scheduled execution.' },
  { name: 'amountUsd', type: 'number', description: 'Amount in USD per cycle.' },
  { name: 'sellAmountUsd', type: 'number', description: 'Sell amount in USD.' },
  { name: 'inputTokenSymbol', type: 'string', description: 'Symbol of the input token.' },
  { name: 'inputTokenName', type: 'string', description: 'Name of the input token.' },
  { name: 'inputTokenImage', type: 'string', description: 'URL of the input token image.' },
  { name: 'outputTokenSymbol', type: 'string', description: 'Symbol of the output token.' },
  { name: 'outputTokenName', type: 'string', description: 'Name of the output token.' },
  { name: 'outputTokenImage', type: 'string', description: 'URL of the output token image.' },
  { name: 'inputMintPrice', type: 'number', description: 'Price of the input mint.' },
  { name: 'outputMintPrice', type: 'number', description: 'Price of the output mint.' },
  { name: 'idx', type: 'number', description: 'Index of the DCA order.' },
  { name: 'nextCycleAt', type: 'number', description: 'UNIX timestamp for the next cycle.' },
  { name: 'inDeposited', type: 'number', description: 'Amount deposited into the input account.' },
  { name: 'inWithdrawn', type: 'number', description: 'Amount withdrawn from the input account.' },
  { name: 'outWithdrawn', type: 'number', description: 'Amount withdrawn from the output account.' },
  { name: 'inUsed', type: 'number', description: 'Amount of input tokens used.' },
  { name: 'outReceived', type: 'number', description: 'Amount of output tokens received.' },
  { name: 'inAmountPerCycle', type: 'number', description: 'Input amount per cycle.' },
  { name: 'cycleFrequency', type: 'number', description: 'Frequency of cycles in seconds.' },
  { name: 'nextCycleAmountLeft', type: 'number', description: 'Amount left for the next cycle.' },
  { name: 'inAccount', type: 'string', description: 'Input account address.' },
  { name: 'outAccount', type: 'string', description: 'Output account address.' },
  { name: 'minOutAmount', type: 'number', description: 'Minimum output amount.' },
  { name: 'maxOutAmount', type: 'number', description: 'Maximum output amount.' },
  { name: 'keeperInBalanceBeforeBorrow', type: 'number', description: "Keeper's input balance before borrowing." },
  { name: 'dcaOutBalanceBeforeSwap', type: 'number', description: 'DCA output balance before swap.' },
  { name: 'createdAt', type: 'number', description: 'UNIX timestamp when the DCA was created.' },
  { name: 'bump', type: 'number', description: 'Bump for Solana accounts.' },
  { name: 'usdSpentPerMinute', type: 'number', description: 'USD spent per minute.' },
  { name: 'progress', type: 'number', description: 'Progress percentage for the DCA order.' },
  { name: 'publicKey', type: 'string', description: 'Public key of the DCA order.' },
];

// Define the Table of Contents items
const TOC_ITEMS = [
  { 
    title: 'Jupiter - Get All DCAs', 
    id: 'get-all-dcas',
    icon: <ApiOutlined />
  },
];

const ApiDocs: React.FC = () => {
  return (
    <div className="api-docs-container">
      {/* Header Card */}
      {/* Separate Header Card */}
      <Card className="header-card">
        <Typography.Title level={2} className="about-title">
          <img 
            src="/cosmic_logo.png" 
            alt="Cosmic Markets Logo" 
            style={{ 
              height: '40px', 
              marginRight: '12px', 
              verticalAlign: 'middle' 
            }} 
          />
          Cosmic Markets API Documentation
        </Typography.Title>
      </Card>

    {/* Notification Alert */}
    <Alert
        message="Cosmic Markets APIs Alpha"
        description="Cosmic Markets APIs are currently in alpha. Please expect breaking changes as we continue development. Additional APIs will be made public soon. Rate limits are currently unmetered; overusage will be addressed on an ad hoc basis."
        type="info"
        showIcon
        style={{ marginBottom: '16px' }}
      />

      {/* Main Content Card */}
      <Card className="api-main-card" bordered={false} style={{ marginTop: '16px' }}>
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          
          {/* Table of Contents */}
          <Card className="api-section-card" size="small">
            <Title level={2}>Public APIs</Title>
            <List
              dataSource={TOC_ITEMS}
              renderItem={item => (
                <List.Item className="toc-item">
                  <a href={`#${item.id}`}>
                    {item.icon && <span style={{ marginRight: '8px' }}>{item.icon}</span>}
                    {item.title}
                  </a>
                </List.Item>
              )}
              size="small"
              bordered={false}
            />
          </Card>

          {/* Get All DCAs Section */}
          <Card className="api-section-card" size="small">
            <Space direction="vertical" size="large" style={{ width: '100%' }}>
              
              <Title level={3} id="get-all-dcas">Jupiter - Get All DCAs</Title>
              <Paragraph>
                Retrieve a list of all Dollar-Cost Averaging (DCA) orders.
              </Paragraph>
              
              {/* Endpoint */}
              <Title level={5}>Endpoint</Title>
              <Paragraph>
                <Text code>GET https://api.cosmic.markets/api/dca/all</Text>
              </Paragraph>
              
              {/* Query Parameters */}
              <Title level={5}>Query Parameters</Title>
              <Table
                dataSource={queryParameters}
                columns={[
                  { title: 'Name', dataIndex: 'name', key: 'name' },
                  { title: 'Type', dataIndex: 'type', key: 'type' },
                  { 
                    title: 'Required', 
                    dataIndex: 'required', 
                    key: 'required',
                    render: (required: boolean) => required ? 'Yes' : 'No' 
                  },
                  { title: 'Description', dataIndex: 'description', key: 'description' },
                ]}
                pagination={false}
                rowKey="name"
                bordered
                size="small"
              />
              
              {/* Response */}
              <Title level={5}>Response</Title>
              <Paragraph>
                Successful response returns a JSON object containing pagination details and an array of DCA orders.
              </Paragraph>
              
              {/* Response Schema */}
              <Title level={5} id="response-schema">Response Schema</Title>
              <Table
                dataSource={responseFields}
                columns={[
                  { title: 'Field', dataIndex: 'name', key: 'name' },
                  { title: 'Type', dataIndex: 'type', key: 'type' },
                  { title: 'Description', dataIndex: 'description', key: 'description' },
                ]}
                pagination={false}
                rowKey="name"
                bordered
                size="small"
              />
              
              {/* ApiDcaOrder Object */}
              <Title level={5} id="apidcaorder-object">ApiDcaOrder Object</Title>
              <Table
                dataSource={apiDcaOrderFields}
                columns={[
                  { title: 'Field', dataIndex: 'name', key: 'name' },
                  { title: 'Type', dataIndex: 'type', key: 'type' },
                  { title: 'Description', dataIndex: 'description', key: 'description' },
                ]}
                pagination={false}
                rowKey="name"
                bordered
                size="small"
              />
              
              {/* Example Request */}
              <Title level={5} id="example-request">Example Request</Title>
              <Paragraph>
                <SyntaxHighlighter language="bash" style={okaidia}>
{`GET https://api.cosmic.markets/api/dca/all?filter=all&page=1&size=10`}
                </SyntaxHighlighter>
              </Paragraph>
              
              {/* Example Response */}
              <Title level={5} id="example-response">Example Response</Title>
              <Paragraph>
                <SyntaxHighlighter language="json" style={okaidia}>
{`{
  "size": 10,
  "totalPages": 5,
  "page": 1,
  "content": [
    {
        "publicKey": "J9vNU6nxZ3ebbV31AX6GF1bxtiVjpjZthohocTxdgAPg",
        "userPublicKey": "DgGPDNX54TDrpStBnFVhUHW8aot8pKiqsZtewVn1cEqR",
        "inputMint": "4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R",
        "outputMint": "oreoU2P8bN6jkk3jbaiVxYnG1dCXcYxwhwyK9jSybcp",
        "amount": 1000.000000,
        "sellAmount": 0.81989250116,
        "scheduledTime": 1731020961000,
        "amountUsd": 4686.64,
        "sellAmountUsd": 23.48,
        "inputTokenSymbol": "RAY",
        "inputTokenName": "Raydium",
        "inputTokenImage": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R/logo.png",
        "outputTokenSymbol": "ORE",
        "outputTokenName": "ORE",
        "outputTokenImage": "https://ore.supply/icon.png",
        "inputMintPrice": 4.686644,
        "outputMintPrice": 28.63407170214,
        "idx": 1731013584,
        "nextCycleAt": 1731020961,
        "inDeposited": 1000000000,
        "inWithdrawn": 0,
        "outWithdrawn": 81989250116,
        "inUsed": 5000000,
        "outReceived": 81989250116,
        "inAmountPerCycle": 2500000,
        "cycleFrequency": 3600,
        "nextCycleAmountLeft": 2500000,
        "inAccount": "FG6ZJdsKmatVWtSUXQZb1FKmCNyhAq3nB89skRRk53ji",
        "outAccount": "EyAdb5grwnTNpZJo9AafQXx9Vbw7y33bcrLi1zgEAQfe",
        "minOutAmount": 0,
        "maxOutAmount": 0,
        "keeperInBalanceBeforeBorrow": 0,
        "dcaOutBalanceBeforeSwap": 0,
        "createdAt": 1731013588,
        "bump": -3,
        "usdSpentPerMinute": 0.20,
        "progress": 0.5
    }
    // ... more DCA orders
  ]
}`}
                </SyntaxHighlighter>
              </Paragraph>
            </Space>
          </Card>
        
        </Space>
      </Card>
    </div>
  );
};

export default ApiDocs;