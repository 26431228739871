import React, { useState, useEffect, useRef } from 'react';
import { Table, Typography, Card, Input, Space, Row, Col } from 'antd';
import { ColumnsType } from 'antd/es/table';
import './TokenList.css';

const { Text } = Typography;

interface TokenData {
  tokenMint: string;
  name: string;
  symbol: string;
  decimals: number;
  logoUrl: string | null;
}

const TokenList: React.FC = () => {
  const [tokens, setTokens] = useState<TokenData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [searchText, setSearchText] = useState<string>('');
  const hasFetched = useRef(false);

  const fetchTokens = async () => {
    setLoading(true);
    try {
      const response = await fetch('https://api.cosmic.markets/api/tokens');
      if (!response.ok) {
        throw new Error(`Error fetching tokens: ${response.statusText}`);
      }
      const data: TokenData[] = await response.json();
      setTokens(data);
    } catch (err) {
      console.error(err);
      setError((err as Error).message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (hasFetched.current) return;
    hasFetched.current = true;
    fetchTokens();
  }, []);

  const dataSource: TokenData[] = tokens.filter((token) => 
    token.name.toLowerCase().includes(searchText.toLowerCase()) ||
    token.symbol.toLowerCase().includes(searchText.toLowerCase()) ||
    token.tokenMint.toLowerCase().includes(searchText.toLowerCase())
  );

  const columns: ColumnsType<TokenData> = [
    {
      title: 'Token',
      dataIndex: 'name',
      key: 'name',
      render: (text: string, record: TokenData) => (
        <a href={`https://solscan.io/token/${record.tokenMint}`} target="_blank" rel="noopener noreferrer">
          {text}
        </a>
      ),
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Symbol',
      dataIndex: 'symbol',
      key: 'symbol',
      sorter: (a, b) => a.symbol.localeCompare(b.symbol),
    },
    {
      title: 'Token Mint',
      dataIndex: 'tokenMint',
      key: 'tokenMint',
      render: (text: string) => (
        <a href={`https://solscan.io/token/${text}`} target="_blank" rel="noopener noreferrer">
          {text}
        </a>
      ),
    },
  ];

  return (
    <Card className="token-list-card" title="Solana Token Database">
      <Space direction="vertical" style={{ width: '100%' }}>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} md={8} lg={6}>
            <Input
              id="search-tokens-input"
              name="searchTokens"
              placeholder="Search tokens..."
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              allowClear
            />
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} md={8} lg={6}>
            <Text strong>
              {dataSource.length} {dataSource.length === 1 ? 'Token' : 'Tokens'}
            </Text>
          </Col>
        </Row>

        {error ? (
          <Typography.Text type="danger">{error}</Typography.Text>
        ) : (
          <Table
            columns={columns}
            dataSource={dataSource}
            rowKey="tokenMint"
            loading={loading}
            pagination={{ pageSize: 10, showSizeChanger: true, pageSizeOptions: ['5', '10', '20'] }}
            className="token-table"
            scroll={{ x: 'max-content' }}
          />
        )}
      </Space>
    </Card>
  );
};

export default TokenList;
