import React from 'react';
import { Layout, Menu, ConfigProvider, theme } from 'antd';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import {
  DashboardOutlined,
  InfoCircleOutlined,
  LineChartOutlined,
  BarChartOutlined,
  HomeOutlined,
  TagOutlined,
  ApiOutlined,
} from '@ant-design/icons';
import type { MenuProps } from 'antd';
import JupiterDCAViewer from './components/JupiterDCAViewer';
import About from './components/About';
import TokenList from './components/TokenList';
import './App.css';
import ApiDocs from './components/ApiDocs';
import DcaOrderDetail from './components/DcaOrderDetail';
import LimitOrdersTable from './components/LimitOrdersTable';

const { Sider, Content } = Layout;

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

/**
 * App component that sets up the layout and routing for the application.
 * Ensures consistent dark theme across all components and includes the application logo and title.
 */
function App() {
  const navigate = useNavigate();
  const location = useLocation();

  const items: MenuItem[] = [
    getItem('Home', '/', <HomeOutlined />),
    getItem('Jupiter', 'jupiter', <LineChartOutlined />, [
      getItem('DCA Orders', '/jupiter/dca', <DashboardOutlined />),
      getItem('Limit Orders', '/jupiter/limit', <BarChartOutlined />),
    ]),
    getItem('Tokens', '/tokens', <TagOutlined />),
    getItem('APIs', '/api/docs', <ApiOutlined />),
    getItem('About', '/about', <InfoCircleOutlined />),
  ];

  const defaultOpenKeys = ['jupiter'];
  const selectedKey = location.pathname === '/' ? '/' : location.pathname;

  return (
    <ConfigProvider
      theme={{
        algorithm: theme.darkAlgorithm,
        token: {
          colorPrimary: '#4caf50',
        },
      }}
    >
      <Layout className="app-layout">
        <Sider 
          className="app-sider" 
          breakpoint="lg"
          collapsedWidth="80"
          trigger={null}
        >
          <div className="logo">
            <img src="/cosmic_logo.png" alt="Logo" className="logo-image" />
            <span className="logo-text">Cosmic Markets</span>
          </div>
          <Menu
            theme="dark"
            mode="inline"
            defaultOpenKeys={defaultOpenKeys}
            selectedKeys={[selectedKey]}
            onClick={({ key }) => navigate(key)}
            items={items}
          />
          <div className="x-logo">
            <a href="https://x.com/cosmic_markets" target="_blank" rel="noopener noreferrer">
              <img src="/x_logo.png" alt="X.com Logo" className="x-logo-image" />
            </a>
          </div>
        </Sider>
        <Layout className="app-content">
          <Content className="app-content-inner">
            <Routes>
              <Route path="/" element={<JupiterDCAViewer />} />
              <Route path="/jupiter/dca" element={<JupiterDCAViewer />} />
              <Route path="/jupiter/limit" element={<LimitOrdersTable title={'Jupiter Limit Orders'} filterKey={'historical'} />} />
              <Route path="/jupiter/limit/:account" element={<LimitOrdersTable title={'Jupiter Limit Orders'} filterKey={'historical'} />} />
              <Route path="/jupiter/dca/:account" element={<DcaOrderDetail />} />
              <Route path="/about" element={<About />} />
              <Route path="/tokens" element={<TokenList />} />
              <Route path="/api/docs" element={<ApiDocs />} />
            </Routes>
          </Content>
        </Layout>
      </Layout>
    </ConfigProvider>
  );
}

export default App;
