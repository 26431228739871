import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Card, Typography, Table, Space, Button, Alert } from 'antd';
import { ArrowLeftOutlined, DownloadOutlined } from '@ant-design/icons';
import { ApiDcaOrder, ApiDcaResponse } from './types';
import './DcaOrderDetail.css';
import { ColumnType } from 'antd/es/table';
import TokenImage from './TokenImage';

const { Title } = Typography;

const DcaOrderDetail: React.FC = () => {
    const { account } = useParams<{ account: string }>();
    const [data, setData] = useState<ApiDcaOrder[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchDcaDetails = async () => {
            setLoading(true);
            try {
                const response = await fetch(`https://api.cosmic.markets/api/dca/all?filter=historical&search=${encodeURIComponent(account || '')}`);
                if (!response.ok) {
                    throw new Error(`Error fetching DCA orders: ${response.statusText}`);
                }
                const result: ApiDcaResponse = await response.json();
                setData(result.content);
            } catch (err) {
                console.error('Fetch DCA Order Detail Error:', err);
                setError((err as Error).message);
            } finally {
                setLoading(false);
            }
        };

        fetchDcaDetails();
    }, [account]);

    const columns: ColumnType<ApiDcaOrder>[] = [
        {
            title: 'DCA Order',
            dataIndex: 'publicKey',
            key: 'publicKey',
            render: (text: string) => (
                <Link to={`/jupiter/dca/${text}`}>
                    {text.length > 8 ? `${text.slice(0, 8)}...` : text}
                </Link>
            ),
        },
        {
            title: 'Trader',
            dataIndex: 'userPublicKey',
            key: 'userPublicKey',
            render: (text: string) => (
                <Link to={`/jupiter/dca/${text}`}>
                    {text.length > 8 ? `${text.slice(0, 8)}...` : text}
                </Link>
            ),
        },
        {
            title: 'Input',
            dataIndex: 'inputTokenSymbol',
            key: 'inputTokenSymbol',
            render: (text: string, record: ApiDcaOrder) => (
                <Space>
                    <TokenImage
                        className="token-image"
                        src={`https://wsrv.nl/?w=32&h=32&default=1&output=gif&n=-1&url=${encodeURIComponent(record.inputTokenImage)}`}
                        alt={text}
                    />
                    <Link to={`/jupiter/dca/${record.inputMint}`}>
                        {text.length > 8 ? `${text.slice(0, 8)}...` : text}
                    </Link>
                </Space>
            ),
            sorter: (a: ApiDcaOrder, b: ApiDcaOrder) => {
                const mintA = a.inputMint.toLowerCase();
                const mintB = b.inputMint.toLowerCase();
                if (mintA < mintB) return -1;
                if (mintA > mintB) return 1;
                return 0;
            },
        },
        {
            title: 'Output',
            dataIndex: 'outputTokenSymbol',
            key: 'outputTokenSymbol',
            render: (text: string, record: ApiDcaOrder) => (
                <Space>
                    <TokenImage
                        className="token-image"
                        src={`https://wsrv.nl/?w=32&h=32&default=1&output=gif&n=-1&url=${encodeURIComponent(record.outputTokenImage)}`}
                        alt={text}
                    />
                    <Link to={`/jupiter/dca/${record.outputMint}`}>
                        {text.length > 8 ? `${text.slice(0, 8)}...` : text}
                    </Link>
                </Space>
            ),
            sorter: (a: ApiDcaOrder, b: ApiDcaOrder) => {
                const mintA = a.outputMint.toLowerCase();
                const mintB = b.outputMint.toLowerCase();
                if (mintA < mintB) return -1;
                if (mintA > mintB) return 1;
                return 0;
            },
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            render: (value: number | null) => (value !== null && value !== undefined ? value.toLocaleString() : 'N/A'),
            sorter: (a: ApiDcaOrder, b: ApiDcaOrder) => a.amount - b.amount,
            align: 'right',
            width: 120,
        },
        {
            title: 'Amount USD',
            dataIndex: 'amountUsd',
            key: 'amountUsd',
            render: (value: number | null) => (value !== null && value !== undefined ? `$${value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : 'N/A'),
            sorter: (a: ApiDcaOrder, b: ApiDcaOrder) => (a.amountUsd || 0) - (b.amountUsd || 0),
            align: 'right',
        },
        {
            title: '$ / Minute',
            dataIndex: 'usdSpentPerMinute',
            key: 'usdSpentPerMinute',
            render: (value: number | null) => (value !== null && value !== undefined ? `$${value.toFixed(2)}` : 'N/A'),
            sorter: (a: ApiDcaOrder, b: ApiDcaOrder) => (a.usdSpentPerMinute || 0) - (b.usdSpentPerMinute || 0),
            align: 'right',
        },
        {
            title: 'Progress',
            dataIndex: 'progress',
            key: 'progress',
            render: (value: number | null) => (value !== null && value !== undefined ? `${value.toFixed(2)}%` : 'N/A'),
            sorter: (a: ApiDcaOrder, b: ApiDcaOrder) => (a.progress || 0) - (b.progress || 0),
            align: 'center',
        },
        {
            title: 'Next Cycle At',
            dataIndex: 'nextCycleAt',
            key: 'nextCycleAt',
            render: (value: number | null) => (value !== null && value !== undefined ? new Date(value * 1000).toLocaleString() : 'N/A'),
            sorter: (a: ApiDcaOrder, b: ApiDcaOrder) => (a.nextCycleAt || 0) - (b.nextCycleAt || 0),
            align: 'center',
            width: 200,
        },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (value: number | null) => (value !== null && value !== undefined ? new Date(value * 1000).toLocaleString() : 'N/A'),
            sorter: (a: ApiDcaOrder, b: ApiDcaOrder) => (a.createdAt || 0) - (b.createdAt || 0),
            align: 'center',
        },
        {
            title: 'In Deposited',
            dataIndex: 'inDeposited',
            key: 'inDeposited',
            render: (value: number | null) => (value !== null && value !== undefined ? value.toLocaleString() : 'N/A'),
            sorter: (a: ApiDcaOrder, b: ApiDcaOrder) => (a.inDeposited || 0) - (b.inDeposited || 0),
            align: 'right',
        },
        {
            title: 'In Withdrawn',
            dataIndex: 'inWithdrawn',
            key: 'inWithdrawn',
            render: (value: number | null) => (value !== null && value !== undefined ? value.toLocaleString() : 'N/A'),
            sorter: (a: ApiDcaOrder, b: ApiDcaOrder) => (a.inWithdrawn || 0) - (b.inWithdrawn || 0),
            align: 'right',
        },
        {
            title: 'Out Withdrawn',
            dataIndex: 'outWithdrawn',
            key: 'outWithdrawn',
            render: (value: number | null) => (value !== null && value !== undefined ? value.toLocaleString() : 'N/A'),
            sorter: (a: ApiDcaOrder, b: ApiDcaOrder) => (a.outWithdrawn || 0) - (b.outWithdrawn || 0),
            align: 'right',
        },
        {
            title: 'In Used',
            dataIndex: 'inUsed',
            key: 'inUsed',
            render: (value: number | null) => (value !== null && value !== undefined ? value.toLocaleString() : 'N/A'),
            sorter: (a: ApiDcaOrder, b: ApiDcaOrder) => (a.inUsed || 0) - (b.inUsed || 0),
            align: 'right',
        },
        {
            title: 'Out Received',
            dataIndex: 'outReceived',
            key: 'outReceived',
            render: (value: number | null) => (value !== null && value !== undefined ? value.toLocaleString() : 'N/A'),
            sorter: (a: ApiDcaOrder, b: ApiDcaOrder) => (a.outReceived || 0) - (b.outReceived || 0),
            align: 'right',
        },
        {
            title: 'In Amount Per Cycle',
            dataIndex: 'inAmountPerCycle',
            key: 'inAmountPerCycle',
            render: (value: number | null) => (value !== null && value !== undefined ? value.toLocaleString() : 'N/A'),
            sorter: (a: ApiDcaOrder, b: ApiDcaOrder) => (a.inAmountPerCycle || 0) - (b.inAmountPerCycle || 0),
            align: 'right',
        },
        {
            title: 'Cycle Frequency',
            dataIndex: 'cycleFrequency',
            key: 'cycleFrequency',
            render: (value: number | null) => (value !== null && value !== undefined ? `${(value / 60).toFixed(2)} minutes` : 'N/A'),
            sorter: (a: ApiDcaOrder, b: ApiDcaOrder) => (a.cycleFrequency || 0) - (b.cycleFrequency || 0),
            align: 'center',
        },
        {
            title: 'Next Cycle Amount Left',
            dataIndex: 'nextCycleAmountLeft',
            key: 'nextCycleAmountLeft',
            render: (value: number | null) => (value !== null && value !== undefined ? value.toLocaleString() : 'N/A'),
            sorter: (a: ApiDcaOrder, b: ApiDcaOrder) => (a.nextCycleAmountLeft || 0) - (b.nextCycleAmountLeft || 0),
            align: 'right',
        },
        {
            title: 'In Account',
            dataIndex: 'inAccount',
            key: 'inAccount',
            render: (text: string) => (
                <Link to={`/accounts/${text}`}>
                    {text.length > 8 ? `${text.slice(0, 8)}...` : text}
                </Link>
            ),
        },
        {
            title: 'Out Account',
            dataIndex: 'outAccount',
            key: 'outAccount',
            render: (text: string) => (
                <Link to={`/accounts/${text}`}>
                    {text.length > 8 ? `${text.slice(0, 8)}...` : text}
                </Link>
            ),
        },
        {
            title: 'Min Out Amount',
            dataIndex: 'minOutAmount',
            key: 'minOutAmount',
            render: (value: number | null) => 
                value !== null && value !== undefined ? value.toLocaleString() : 'N/A',
            sorter: (a: ApiDcaOrder, b: ApiDcaOrder) => a.minOutAmount - b.minOutAmount,
            align: 'right',
        },
        {
            title: 'Max Out Amount',
            dataIndex: 'maxOutAmount',
            key: 'maxOutAmount',
            render: (value: number | null) => 
                value !== null && value !== undefined ? value.toLocaleString() : 'N/A',
            sorter: (a: ApiDcaOrder, b: ApiDcaOrder) => a.maxOutAmount - b.maxOutAmount,
            align: 'right',
        },
        {
            title: 'Keeper In Balance Before Borrow',
            dataIndex: 'keeperInBalanceBeforeBorrow',
            key: 'keeperInBalanceBeforeBorrow',
            render: (value: number | null) => 
                value !== null && value !== undefined ? value.toLocaleString() : 'N/A',
            sorter: (a: ApiDcaOrder, b: ApiDcaOrder) => a.keeperInBalanceBeforeBorrow - b.keeperInBalanceBeforeBorrow,
            align: 'right',
        },
        {
            title: 'DCA Out Balance Before Swap',
            dataIndex: 'dcaOutBalanceBeforeSwap',
            key: 'dcaOutBalanceBeforeSwap',
            render: (value: number | null) => 
                value !== null && value !== undefined ? value.toLocaleString() : 'N/A',
            sorter: (a: ApiDcaOrder, b: ApiDcaOrder) => a.dcaOutBalanceBeforeSwap - b.dcaOutBalanceBeforeSwap,
            align: 'right',
        },
        {
            title: 'Input Price USD',
            dataIndex: 'inputMintPrice',
            key: 'inputMintPrice',
            render: (value: number | null | undefined) => 
                value !== null && value !== undefined 
                    ? `$${value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` 
                    : 'N/A',
            sorter: (a: ApiDcaOrder, b: ApiDcaOrder) => {
                const priceA = a.inputMintPrice ?? 0;
                const priceB = b.inputMintPrice ?? 0;
                return priceA - priceB;
            },
            align: 'right',
        },
        {
            title: 'Output Price USD',
            dataIndex: 'outputMintPrice',
            key: 'outputMintPrice',
            render: (value: number | null | undefined) => 
                value !== null && value !== undefined 
                    ? `$${value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` 
                    : 'N/A',
            sorter: (a: ApiDcaOrder, b: ApiDcaOrder) => {
                const priceA = a.outputMintPrice ?? 0;
                const priceB = b.outputMintPrice ?? 0;
                return priceA - priceB;
            },
            align: 'right',
        },
        {
            title: 'IDX',
            dataIndex: 'idx',
            key: 'idx',
            sorter: (a: ApiDcaOrder, b: ApiDcaOrder) => a.idx - b.idx,
            align: 'center',
        },
        {
            title: 'Bump',
            dataIndex: 'bump',
            key: 'bump',
            sorter: (a: ApiDcaOrder, b: ApiDcaOrder) => a.bump - b.bump,
            align: 'center',
        },
    ];

    const exportToCSV = () => {
        if (data.length === 0) {
            alert('No data available to export.');
            return;
        }

        // Define the headers based on the columns
        const headers = columns.map(col => col.title);

        // Map the data to rows
        const rows = data.map(row => {
            return columns.map(col => {
                const value = row[col.dataIndex as keyof ApiDcaOrder];
                if (typeof value === 'number') {
                    return value;
                } else if (typeof value === 'string') {
                    // Escape double quotes in the string
                    const escaped = value.replace(/"/g, '""');
                    return `"${escaped}"`;
                } else {
                    return 'N/A';
                }
            });
        });

        // Combine headers and rows
        const csvContent = [
            headers.join(','), // Header row
            ...rows.map(row => row.join(',')) // Data rows
        ].join('\n');

        // Create a Blob from the CSV content
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

        // Create a link to download the Blob
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.href = url;
        link.setAttribute('download', `dca_order_detail_${Date.now()}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div className="dca-order-detail-container">
            <Space direction="vertical" size="large" style={{ width: '100%' }}>
                <Space>
                    <Button icon={<ArrowLeftOutlined />} href="/jupiter/dca">
                        Back to DCA Dashboard
                    </Button>
                    <Button icon={<DownloadOutlined />} onClick={exportToCSV}>
                        Export CSV
                    </Button>
                </Space>
                <Card>
                    <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                        <Title level={3}>
                            DCA Order Details for {account}
                            <a
                                href={`https://solscan.io/account/${account}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ marginLeft: '8px' }}
                            >
                                <img
                                    src="/solscan.png" // Replace with your icon path or URL
                                    alt="Solscan"
                                    className="solscan-icon"
                                />
                            </a>
                        </Title>
                        {error && <Alert message="Error" description={error} type="error" showIcon />}
                        <div style={{ overflowX: 'auto' }}>
                            <Table
                                columns={columns}
                                dataSource={data}
                                loading={loading}
                                rowKey="publicKey"
                                pagination={{ pageSize: 10 }}
                                bordered
                                scroll={{ x: 'max-content' }}
                            />
                        </div>
                    </Space>
                </Card>
            </Space>
        </div>
    );
};

export default DcaOrderDetail;
